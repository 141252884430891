<template>
  <div>
    <b-row>
      <b-col sm="12" md="12">
        <div class="d-flex justify-content-center">
          <b-button-group class="mb-2">
            <b-button
              :disabled="button.add"
              pill
              variant="success"
              @click="addClick()"
              >เพิ่ม</b-button
            >
            <b-button
              :disabled="button.edit"
              pill
              variant="warning"
              @click="editClick()"
              >แก้ไข</b-button
            >
            <b-button
              :disabled="button.cancel"
              pill
              variant="secondary"
              @click="cancelClick"
              >ยกเลิก</b-button
            >

            <b-button
              :disabled="button.delete"
              pill
              variant="danger"
              @click="deleteClick"
              >ลบ</b-button
            >
            <b-button
              :disabled="button.search"
              pill
              variant="primary"
              @click="showmodalRefuel"
              >ค้นหา</b-button
            >
          </b-button-group>
        </div>
      </b-col>
    </b-row>
    <b-card title="บันทึกการเติมน้ำมัน" class="mb-3">
      <b-card-body>
        <b-row>
          <b-col sm="12" md="12">
            <b-form-group
              label-sm="12"
              label-cols-md="3"
              label="วันที่เติมน้ำมัน"
            >
              <b-form-datepicker
                :disabled="disabled"
                id="datepicker-from"
                today-button
                reset-button
                close-button
                :date-format-options="{
                  year: 'numeric',
                  month: 'numeric',
                  day: 'numeric',
                }"
                locale="th"
                v-model.trim="$v.data.docDate.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.docDate),
                }"
              ></b-form-datepicker>
              <span v-if="!$v.data.docDate.required" class="invalid-feedback">
                ยังไมได้ใส่วันที่เติมน้ำมัน
              </span>
            </b-form-group>
          </b-col>
        </b-row>
        <b-row>
          <b-col sm="12" md="12">
            <b-form-group
              label-sm="12"
              label-cols-md="3"
              label="สถานที่เติมน้ำมัน"
            >
              <b-form-textarea
                :disabled="disabled"
                v-model.trim="$v.data.location.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.location),
                }"
              >
              </b-form-textarea>
              <span v-if="!$v.data.location.required" class="invalid-feedback">
                ยังไมได้ใส่สถานที่เติมน้ำมัน
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12">
            <b-form-group label-sm="12" label-cols-md="3" label="จำนวนเงิน">
              <b-form-input
                :disabled="disabled"
                @keypress="isNumber($event)"
                v-model.trim="$v.data.total.$model"
                :class="{
                  'is-invalid': validationStatus($v.data.total),
                }"
              >
              </b-form-input>
              <span v-if="!$v.data.total.required" class="invalid-feedback">
                ยังไมได้ใส่จำนวนเงิน
              </span>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col sm="12" md="12">
            <b-form-group label-sm="12" label-cols-md="3" label="รูปภาพประกอบ">
              <b-form-file
                :disabled="disabled"
                v-model="image"
                placeholder="Choose an image"
                class="w-auto flex-grow-1"
                accept="image/*"
                type="file"
              ></b-form-file>
              <b-button
                :disabled="disabled"
                v-if="hasImage"
                variant="danger"
                class="ml-3"
                @click="clearImage"
                >ลบ</b-button
              >
            </b-form-group>

            <b-img
              v-if="hasImage"
              :src="imageSrc"
              class="mb-3"
              fluid
              block
              img-height="200"
              img-width="200"
              alt="Responsive image"
            ></b-img>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    <b-button
      class="mt-3"
      :disabled="button.save"
      block
      variant="primary"
      @click="saveRefuel"
      >บันทึก</b-button
    >

    <b-modal size="lg" centered id="modal-searchRefuel" hide-footer>
      <template #modal-title>ค้นหาข้อมูลเติมน้ำมัน</template>

      <b-row>
        <b-col sm="12" md="5">
          <b-form-group label="วันที่">
            <b-form-datepicker
              id="datepicker-valid"
              today-button
              reset-button
              close-button
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model.trim="$v.search.fromDate.$model"
              :class="{
                'is-invalid': validationStatus($v.search.fromDate),
              }"
            ></b-form-datepicker>
            <span v-if="!$v.search.fromDate.required" class="invalid-feedback">
              ยังไมได้ใส่วันที่เอกสาร
            </span>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="5">
          <b-form-group label="วันที่">
            <b-form-datepicker
              id="datepicker-from"
              today-button
              reset-button
              close-button
              :date-format-options="{
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }"
              locale="th"
              v-model.trim="$v.search.toDate.$model"
              :class="{
                'is-invalid': validationStatus($v.search.toDate),
              }"
            ></b-form-datepicker>

            <span v-if="!$v.search.toDate.required" class="invalid-feedback">
              ยังไมได้ใส่วันที่เอกสาร
            </span>
          </b-form-group>
        </b-col>

        <b-col sm="12" md="2">
          <b-button variant="warning" class="md-mt-4 mt-4" @click="searchRefuel"
            >ค้นหา</b-button
          >
        </b-col>
      </b-row>

      <b-row>
        <b-col sm="12" md="12">
          <b-card>
            <b-card-body>
              <b-table
                fixed
                show-empty
                hover
                responsive
                sticky-header="600px"
                :items="datatable.items"
                :fields="datatable.fields"
                :busy.sync="datatable.isBusy"
              >
                <template #table-busy>
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                    <strong>Loading...</strong>
                  </div>
                </template>

                <template v-slot:cell(index)="row">
                  {{ row.index + 1 }}
                </template>

                <template #cell(docDate)="row">
                  {{ formatDate(row.item.docDate) }}
                </template>

                <template #cell(action)="row">
                  <b-button
                    size="sm"
                    class="mr-1"
                    pill
                    variant="outline-info"
                    @click="getRefuel(row.item.id)"
                  >
                    เลือก
                  </b-button>
                </template>
              </b-table>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import swal from "sweetalert2";
import moment from "moment";

const base64Encode = (data) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(data);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export default {
  name: "refuel",
  data() {
    return {
      disabled: true,

      image: null,
      imageSrc: null,

      button: {
        add: false,
        edit: true,
        delete: true,
        search: false,
        cancel: true,
        save: true,
      },

      data: {
        id: 0,
        docDate: "",
        location: "",
        total: "",
        EditStatus: "",
      },

      search: {
        fromDate: "",
        toDate: "",
      },

      datatable: {
        items: [],
        fields: [
          {
            key: "index",
            label: "ลำดับ",
            thStyle: { width: "100px" },
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "docDate",
            label: "วันที่",
            thStyle: { width: "150px" },
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "location",
            label: "สถานที่",
            thStyle: { width: "200px" },
            thClass: "text-center",
            tdClass: "text-center",
          },
          {
            key: "action",
            label: "เลือก",
            thStyle: { width: "80px" },
            thClass: "text-center",
            tdClass: "text-center",
          },
        ],
      },
    };
  },

  computed: {
    hasImage() {
      return !!this.imageSrc;
    },
  },

  watch: {
    image(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (newValue) {
          base64Encode(newValue)
            .then((value) => {
              this.imageSrc = value;
            })
            .catch(() => {
              this.imageSrc = null;
            });
        } else {
          this.imageSrc = null;
        }
      }
    },
  },

  methods: {
    validationStatus: function (validation) {
      return typeof validation != "undefined" ? validation.$error : false;
    },

    async clearImage() {
      try {
        if (this.data.id == 0) {
          this.image = null;
          this.imageSrc = null;
          return;
        }

        var response = await this.$http({
          method: "Delete",
          url: "api/SaleMan/DeletePicRefuel/" + this.data.id,
        });

        if (response.status == 200) {
          this.image = null;
          this.imageSrc = null;
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == "500") {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == "400") {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    formatDate(value) {
      if (value == null) {
        return;
      }
      return moment(String(value)).format("DD/MM/YYYY ");
    },

    async saveRefuel() {
      this.$v.data.$touch();
      if (this.$v.data.$pendding || this.$v.data.$error) return;

      try {
        var response = await this.$http({
          method: "POST",
          url:
            "api/SaleMan/saveRefuel/" +
            window.localStorage.getItem("saleCode") +
            "/" +
            window.localStorage.getItem("dbId"),
          data: this.data,
        });
        if (response.status == 200) {
          if (this.image != null) {
            this.savePicture(response.data);
          }

          swal
            .fire({
              icon: "info",
              title: "บันทึกการเติมน้ำมันเรียบร้อยแล้ว",
              confirmButtonText: "ตกลง",
            })
            .then((result) => {
              if (result) {
                this.activityId = response.data;

                this.button = {
                  add: false,
                  edit: true,
                  delete: true,
                  search: false,
                  cancel: true,
                  save: true,
                };

                this.data = {
                  docDate: "",
                  location: "",
                  total: "",
                  EditStatus: "",
                  id: 0,
                };
              }
            });
        }
      } catch (error) {
        console.log(error);
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async savePicture(id) {
      try {
        var fdata = new FormData();
        fdata.append("file", this.image);

        var response = await this.$http({
          method: "Post",
          url: "api/SaleMan/SaveRefuelActivity/" + id,
          data: fdata,
        });

        if (response.status == "200") {
          this.image = null;
          this.imageSrc = null;
        }
      } catch (error) {
        console.log(error);

        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async getRefuel(id) {
      try {
        var response = await this.$http({
          method: "GET",
          url: "api/SaleMan/getRefuelById/" + id,
        });
        if (response.status == 200) {
          this.data = response.data;
          this.$root.$emit("bv::hide::modal", "modal-searchRefuel");

          var img = "";
          img = response.data.fileName;

          if (img == null) {
            img = "";
          }

          if (img !== "") {
            this.getImage(this.data.fileName);
          }
          this.disabled = true;

          this.button = {
            add: false,
            edit: false,
            delete: false,
            search: false,
            cancel: false,
            save: true,
          };
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    async getImage(id) {
      try {
        console.log(id);

        var response = await this.$http({
          method: "get",
          url: "api/SaleMan/GetPicRefuel/" + id,
          responseType: "arraybuffer",
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
        });

        if (response.status == 200) {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          this.imageSrc = url;
        }
      } catch (error) {
        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    addClick() {
      this.button = {
        add: true,
        edit: true,
        delete: false,
        search: true,
        cancel: false,
        save: false,
      };

      this.data = {
        total: "",
        docDate: "",
        location: "",
      };

      this.disabled = false;
    },

    editClick() {
      if (this.data.editStatus == "Y") {
        swal.fire({
          icon: "info",
          title: "Alert",
          text: "รายการมีการยืนยันการปฏิบัติงานแล้ว",
        });

        return;
      }

      this.button = {
        add: true,
        edit: true,
        delete: false,
        search: true,
        cancel: false,
        save: false,
      };

      this.disabled = false;
    },

    cancelClick() {
      this.data = {
        docDate: "",
        location: "",
        total: "",
        EditStatus: "",
      };

      this.button = {
        add: false,
        edit: true,
        delete: true,
        search: false,
        cancel: true,
        save: true,
      };

      this.disabled = true;
    },

    deleteClick() {
      if (this.data.editStatus == "Y") {
        swal.fire({
          icon: "info",
          title: "Alert",
          text: "รายการมีการยืนยันการปฏิบัติงานแล้ว",
        });
        return;
      }

      swal
        .fire({
          icon: "info",
          title: "คุณต้องการลบรายการนี้ใช่ไหม",
          showCancelButton: true,
          confirmButtonText: "OK",
          denyButtonText: `Cancel`,
        })
        .then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.deleteRefuel(this.data.id);
          }
        });

      this.button = {
        add: false,
        edit: true,
        delete: true,
        search: false,
        cancel: true,
        save: true,
      };

      this.disabled = true;
    },

    async searchRefuel() {
      try {
        this.$v.search.$touch();
        if (this.$v.search.$pendding || this.$v.search.$error) return;

        var params = new URLSearchParams();
        params.append("fromdate", this.search.fromDate);
        params.append("todate", this.search.toDate);

        var response = await this.$http({
          method: "GET",
          url:
            "api/SaleMan/getRefuel/" +
            window.localStorage.getItem("saleCode") +
            "/" +
            window.localStorage.getItem("dbId"),
          params: params,
        });
        if (response.status == 200) {
          this.datatable.items = response.data;
        }
      } catch (error) {
        console.log(error);

        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },

    showmodalRefuel() {
      this.datatable.items = [];
      this.$root.$emit("bv::show::modal", "modal-searchRefuel");
    },

    async deleteRefuel(id) {
      try {
        var response = await this.$http({
          method: "delete",
          url: "api/SaleMan/DeleteRefuel/" + id,
        });

        if (response.status == 200) {
          this.cancelClick();
        }
      } catch (error) {
        console.log(error);

        if (error.response.status == 404) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.title,
          });
        } else if (error.response.status == 401) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 403) {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: "ไม่พบสิทธิ์การใช้งาน",
          });
        } else if (error.response.status == 500) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.response.data.detail,
          });
        } else if (error.response.status == 400) {
          swal.fire({
            icon: "error",
            title: "Alert",
            text: error.request.responseText,
          });
        } else if (error.request) {
          swal.fire({
            icon: "error",
            title: "Error Requset",
            text: error.request.text,
          });
        } else {
          swal.fire({
            icon: "error",
            title: "Authorize",
            text: error,
          });
        }
      }
    },
  },

  validations: {
    data: {
      docDate: { required },
      location: { required },
      total: { required },
    },

    search: {
      fromDate: { required },
      toDate: { required },
    },
  },
};
</script>
